import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../../Components/assets/img/logo_home_navbar.png";
import { IP } from "../../Constant";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDashboard,
  faCalendarAlt,
  faConciergeBell,
  faMoneyBillWave,
  faBell,
  faStar,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons"; // Import icons you will use

const SideBar = () => {
  const nav = useNavigate();
  const [review, setReview] = useState(0);
  const [user, setUser] = useState("");
  const [notification, setNotifications] = useState(0);
  const [valuestatus, setZerovalue] = useState(false)
  const application_status = localStorage.getItem("application_status");
  const token = localStorage.getItem("providertoken")
  const [activeTab, setActiveTab] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [notificationSum, setNotificationSum] = useState(0);

  let userId = localStorage.getItem("userid")
  const user_id = localStorage.getItem("provider_id");

  useEffect(() => {
    const token = localStorage.getItem("providertoken");

    try {
      fetch(`${IP}/provider/application-status`, {
        headers: {
          Authorization: token,
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          setUser(result.application_status);
          localStorage.setItem("application_status", result.application_status);
          console.log("application-status", result.application_status);
          if (result.application_status >= 3) {
            localStorage.setItem("approvaluser", "approval");
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [nav]);





  const handleNotificationClick = async (type) => {
    try {
      const response = await fetch(`${IP}/notifications/${user_id}/status?type=${type}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token, // Include token here
        },
        body: JSON.stringify({ status: 0 }), // Set status to 0
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      const result = await response.json();
      console.log(`${type.charAt(0).toUpperCase() + type.slice(1)} status updated:`, result);
      setZerovalue(true);

      // Navigate to 
      // nav(type === 'notification' ? "/providers/events" : "#");
    } catch (error) {
      console.error(`Error updating ${type} status:`, error);
    }
  };


  const fetchNotificationStatusSum = async () => {
    try {
      const response = await fetch(`${IP}/notifications/user/${user_id}/status/sum`, {
        headers: {
          Authorization: token,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch notification status sum');
      }
      const result = await response.json();
      console.log("Notification value retrieved");
      setNotificationSum(result);
      localStorage.setItem("summationsummationsummationsummationsummationsummationsummation", result)
      // console.log("Notification status sum:Notification status sum:Notification status sum:Notification status sum:Notification status sum:Notification status sum:", result);
    } catch (error) {
      console.error("Error fetching notification status sum:", error);
    }
  };
  useEffect(() => {
    fetchNotificationStatusSum()
  }, [valuestatus])


  // {notificationSum?.notificationTotalStatus}
  return (
    <>
      <div className="sidebar col-md-3 sticky">
        <div className="sidebar-title">
          <Link className="navbar-brand" to="/providers">
            <img
              src={image1}
              width="200"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Link>
        </div>
        <div className="sidebar-menu">
          {application_status >= "4" ? (
            <>
              <Link to="/providers">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faDashboard} className="menu-icon" />
                  Dashboard
                  {
                    notificationSum?.providerBoiking > 0 ? (
                      <span style={{
                        marginLeft: "15px",
                        backgroundColor: 'green', // Red background for visibility
                        color: '#000', // White text color
                        borderRadius: '100%', // Rounded corners
                        padding: '5px 5px', // Padding for better spacing
                        fontSize: '14px', // Font size
                        verticalAlign: 'middle' // Aligns it nicely with the text
                      }}>
                        {notificationSum?.providerBoiking}

                      </span>
                    ) : (
                      null
                    )
                  }
                </div>
              </Link>

              <Link to="/providers/events">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
                  Appointments

                </div>
              </Link>

              <Link to="/providers/services">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faConciergeBell} className="menu-icon" />
                  Services
                </div>
              </Link>

              <Link to="/providers/earnings">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faMoneyBillWave} className="menu-icon" />
                  Earnings
                </div>
              </Link>

              <Link to="/providers/notification">

                <div className="menu-item" onClick={() => {
                  handleNotificationClick("notification");

                }}>
                  <FontAwesomeIcon icon={faBell} className="menu-icon" />
                  Notification
                  {
                    notificationSum?.notificationTotalStatus > 0 ? (
                      <span style={{
                        marginLeft: "15px",
                        backgroundColor: 'green', // Red background for visibility
                        color: '#000', // White text color
                        borderRadius: '100%', // Rounded corners
                        padding: '2px 8px', // Padding for better spacing
                        fontSize: '12px', // Font size
                        verticalAlign: 'middle' // Aligns it nicely with the text
                      }}>


                        {notificationSum?.notificationTotalStatus}
                      </span>
                    ) : (
                      null
                    )
                  }

                </div>
              </Link>

              <Link to="/providers/review">
                <div className="menu-item" onClick={() => {
                  handleNotificationClick("review");

                }}>
                  <FontAwesomeIcon icon={faStar} className="menu-icon" />
                  Review

                  {
                    notificationSum?.reviewTotalStatus > 0 ? (
                      <span style={{
                        marginLeft: "15px",
                        backgroundColor: 'green', // Red background for visibility
                        color: '#000', // White text color
                        borderRadius: '100%', // Rounded corners
                        padding: '5px 5px', // Padding for better spacing
                        fontSize: '14px', // Font size
                        verticalAlign: 'middle' // Aligns it nicely with the text
                      }}>

                        {notificationSum?.reviewTotalStatus}
                      </span>
                    ) : (
                      null
                    )
                  }



                </div>
              </Link>


              {application_status !== "4" && (
                <Link to="/providers/application-form">
                  <div className="menu-item">
                    <FontAwesomeIcon icon={faFileAlt} className="menu-icon" />
                    Application Form
                  </div>
                </Link>
              )}
            </>
          ) : (
            <>
              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faDashboard} className="menu-icon" />
                  Dashboard
                </div>
              </Link>

              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
                  My Events
                </div>
              </Link>

              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faConciergeBell} className="menu-icon" />
                  Services
                </div>
              </Link>

              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faMoneyBillWave} className="menu-icon" />
                  Earnings
                </div>
              </Link>

              <Link to="/providers/application-form">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faFileAlt} className="menu-icon" />
                  Application Form
                </div>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
